<template lang="pug">
.committee-admin-create-edit-dialog 
  CreateEditDialog(
    title="Add Committee Admin"
    :item="newUser"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveCommittee"
  )
    template( 
      v-slot:form="{ clone, rules }"
    )
      .d-flex.gap-8
        v-text-field(
          outlined
          dense
          :rules="[rules.required]"
          label="Firstname"
          v-model="clone.firstName"
        )

        v-text-field(
          outlined
          dense
          :rules="[rules.required]"
          label="Lastname"
          v-model="clone.lastName"
        )

      v-text-field(
        outlined
        dense
        :rules="[rules.required, rules.email]"
        label="Email"
        v-model="clone.email"
      )

      v-text-field(
        outlined
        dense
        :rules="[rules.phone]"
        label="Phone"
        v-model="clone.phone"
      )

      StateList(
        v-model="clone.stateId"
      )

</template>

<script>
import StateList from '@/components/StateList'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
import formValidations from '../../helpers/formValidations'

export default {
  name: 'CommitteeAdminCreateEditDialog',

  props: {
    title: {
      type: String,
      default: 'Add Committee Admin'
    },
    isOpen: Boolean,
    editingUser: Object
  },

  components: {
    StateList,
    CreateEditDialog
  },

  methods: {
    async saveCommittee({ save, clone }) {
      try {
        clone.group = 'committee';
        
        await save()
        this.$snackSuccess('Successfully added committee admin')
        this.$emit('close', true)
      } catch (e) {
        this.$snackError(e)
      }
    }
  },

  setup(props, { root }) {
    const { valid, rules } = formValidations()
    const { User } = root.$FeathersVuex.api

    const committeeAdminDefaults = { 
      group: 'committee', 
      status: 'active', 
      roleId: 3, 
      stateId: 1 
    }
    const newUser = new User(props.editingUser || committeeAdminDefaults)

    return {
      rules,
      valid,

      newUser
    }
  }
}
</script>

<style>

</style>